export enum TabIdentifier {
  Metadata = "metadata-editor-tab",
  Pages = "pages-editor-tab",
  Regions = "regions-editor-tab",
  Attributes = "attributes-editor-tab",
  ReadingOrder = "reading-order-editor-tab",
}

export enum TabPrefix {
  AlternativeTextCreation = "alternative-text-creation-pane",
  AlternativeTextUpdate = "alternative-text-update-pane",
  CaptionCreation = "caption-creation-pane",
  CaptionUpdate = "caption-update-pane",
  ExcludeReadingOrderCreation = "exclude-reading-order-creation-pane",
  ExcludeReadingOrderUpdate = "exclude-reading-order-update-pane",
  ExpressionCreation = "expression-creation-pane",
  ExpressionUpdate = "expression-update-pane",
  FigureCreation = "figure-creation-pane",
  FigureUpdate = "figure-update-pane",
  HeadingLevelCreation = "heading-level-creation-pane",
  HeadingLevelUpdate = "heading-level-update-pane",
  ImageCreation = "image-creation-pane",
  ImageUpdate = "image-update-pane",
  ListCreation = "list-creation-pane",
  ListUpdate = "list-update-pane",
  ListItemCreation = "list-item-creation-pane",
  ListItemUpdate = "list-item-update-pane",
  ListItemLabelCreation = "list-item-label-creation-pane",
  ListItemLabelUpdate = "list-item-label-update-pane",
  PaginationArtifactCreation = "pagination-artifact-creation-pane",
  PaginationArtifactUpdate = "pagination-artifact-update-pane",
  TableCreation = "table-creation-pane",
  TableUpdate = "table-update-pane",
  TableCellCreation = "table-cell-creation-pane",
  TableCellUpdate = "table-cell-update-pane",
  TableHeaderCellCreation = "table-header-cell-creation-pane",
  TableHeaderCellUpdate = "table-header-cell-update-pane",
  TableOfContentsCreation = "table-of-contents-creation-pane",
  TableOfContentsUpdate = "table-of-contents-update-pane",
  TableOfContentsHeadingCreation = "table-of-contents-heading-creation-pane",
  TableOfContentsHeadingUpdate = "table-of-contents-heading-update-pane",
  TableOfContentsItemCreation = "table-of-contents-item-creation-pane",
  TableOfContentsItemUpdate = "table-of-contents-item-update-pane",
  TableOfContentsPageNumberCreation = "table-of-contents-page-number-creation-pane",
  TableOfContentsPageNumberUpdate = "table-of-contents-page-number-update-pane",
  TextCreation = "text-creation-pane",
  TextUpdate = "text-update-pane",
}

export enum RegionMode {
  Rectangle = "rectangle",
  Polygon = "polygon",
}

export type SwitchRegionModeEditorAction = {
  type: "switchMode";
  mode: RegionMode;
};

export type SelectRegionEditorAction = {
  type: "selectRegion";
  regionId: string;
};

export type DeselectRegionEditorAction = {
  type: "deselectRegion";
};

export type SwitchTabEditorAction = {
  type: "switchTab";
  tabIdentifier: string;
};

export type EditorAction =
  | SwitchRegionModeEditorAction
  | SelectRegionEditorAction
  | DeselectRegionEditorAction
  | SwitchTabEditorAction;

export type EditorState = {
  mode: RegionMode;
  tabIdentifier: string;
  selectedRegionId?: string;
};

export function editorReducer(
  state: EditorState,
  action: EditorAction,
): EditorState {
  if (action.type === "switchMode") {
    return {
      ...state,
      mode: action.mode,
    };
  }

  if (action.type === "selectRegion") {
    return {
      ...state,
      selectedRegionId: action.regionId,
    };
  }

  if (action.type === "deselectRegion") {
    return {
      ...state,
      selectedRegionId: undefined,
    };
  }

  if (action.type === "switchTab") {
    return {
      ...state,
      tabIdentifier: action.tabIdentifier,
    };
  }

  return state;
}

export function makeIdentifier(tabPrefix: TabPrefix, nodeId: string): string {
  return `${tabPrefix}-${nodeId}`;
}
