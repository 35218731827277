import { Dispatch, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  CaptionUpdateTabFragment,
  useUpdateCaptionAttributeMutation,
} from "../../client/generated";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import AttributeDeleteButton from "../AttributeDeleteButton";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";
import CaptionRegionSelect from "./CaptionRegionSelect";

type Props = {
  fragment: CaptionUpdateTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function CaptionUpdateTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [linkedRegion, setLinkedRegion] = useState(
    fragment.linkedRegionId ?? "",
  );

  const [captionUpdate, { loading }] = useUpdateCaptionAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await captionUpdate({
      variables: {
        attributeId: fragment.id,
        linkedRegionId: linkedRegion,
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.CaptionUpdate, fragment.id)}
    >
      <NavbarTabHeader
        title={t("CaptionUpdateTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <CaptionRegionSelect
          fragment={fragment.region.page.document}
          value={linkedRegion}
          onValueChange={setLinkedRegion}
        />
        <div className="flex flex-col gap-2">
          <AccessButton type="submit" loading={loading}>
            {t("CaptionUpdateTab.labels.confirm")}
          </AccessButton>
          <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
        </div>
      </form>
    </NavbarTabContent>
  );
}
