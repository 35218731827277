import { Link } from "react-router";
import { routingPaths } from "../navigation/constants";
import { DocumentNameTableCellFragment } from "../client/generated";
import { Icon } from "@mdi/react";
import { mdiAccountMultiple } from "@mdi/js";
import { Badge } from "@acdc2/ui/components/badge";

type Props = {
  fragment: DocumentNameTableCellFragment;
};

export default function DocumentNameTableCell({
  fragment,
}: Props): JSX.Element {
  return (
    <div className="flex flex-col gap-2">
      <Link to={routingPaths.document(fragment.id)}>
        {fragment.name}{" "}
        {fragment.members.total > 1 && (
          <Icon size="1rem" path={mdiAccountMultiple} className="inline" />
        )}
      </Link>
      <div className="flex flex-wrap gap-x-1 gap-y-2">
        {fragment.tags.map((tag) => (
          <Badge key={tag} variant="outline">
            {tag}
          </Badge>
        ))}
      </div>
    </div>
  );
}
