import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { mdiAccountPlus } from "@mdi/js";
import { Trans, useTranslation } from "react-i18next";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { DocumentShareCombobox } from "./DocumentShareCombobox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/dialog";
import {
  DocumentShareDrowdownMenuItemFragment,
  useShareDocumentMutation,
} from "../client/generated";
import DocumentShareCollaborator from "./DocumentShareCollaborator";
import DocumentSharePermission from "./DocumentSharePermission";

type Props = {
  fragment: DocumentShareDrowdownMenuItemFragment;
};

export default function DocumentShareDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [shareDocument] = useShareDocumentMutation();

  const handleShare = async (userId: string) => {
    try {
      await shareDocument({
        variables: {
          documentId: fragment.id,
          userId,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiAccountPlus}
            description={t("DocumentShareDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("DocumentShareDropdownMenuItem.dialog.title")}
          </DialogTitle>
          <DialogDescription>
            <Trans
              i18nKey="DocumentRenameDropdownMenuItem.dialog.description"
              components={{ "word-break": <span className="break-all" /> }}
              values={{ name: fragment.name }}
            />
          </DialogDescription>
        </DialogHeader>

        <DocumentShareCombobox
          fragment={fragment.viewerMembership.user.colleagues}
          onSelect={handleShare}
        />

        <div className="flex flex-col gap-3">
          <DialogTitle asChild>
            <h3>
              {t("DocumentShareDropdownMenuItem.dialog.collaborators.title")}
            </h3>
          </DialogTitle>
          <ul className="-mx-6 flex flex-col gap-4">
            {fragment.members.memberships.map((membership) => (
              <li key={membership.user.id} className="px-6">
                <DocumentShareCollaborator
                  fragment={membership.user}
                  right={<DocumentSharePermission fragment={membership} />}
                />
              </li>
            ))}
          </ul>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <AccessButton>
              {t("DocumentShareDropdownMenuItem.dialog.done")}
            </AccessButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
