import { Dispatch, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabIdentifier,
  TabPrefix,
} from "../../editor/reducers";
import { useTranslation } from "react-i18next";
import {
  TableCreationTabFragment,
  useCreateTableAttributeMutation,
} from "../../client/generated";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";
import ParentColumnOffsetInput from "./ParentColumnOffsetInput";
import ParentRowOffsetInput from "./ParentRowOffsetInput";
import TableColumnList from "./TableColumnList";
import TableRowList from "./TableRowList";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { UnsavedColumn, UnsavedRow } from "./types";

type Props = {
  fragment: TableCreationTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function TableCreationTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [parentRowOffset, setParentRowOffset] = useState<number>();
  const [parentColumnOffset, setParentColumnOffset] = useState<number>();
  const [rows, setRows] = useState<UnsavedRow[]>([]);
  const [columns, setColumns] = useState<UnsavedColumn[]>([]);

  const [createTableAttribute] = useCreateTableAttributeMutation();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      parentRowOffset === undefined ||
      parentColumnOffset === undefined ||
      rows.length === 0 ||
      columns.length === 0
    ) {
      return;
    }

    await createTableAttribute({
      variables: {
        regionId: fragment.id,
        parentRowOffset,
        parentColumnOffset,
        rows: rows.map((row) => row.height),
        columns: columns.map((column) => column.width),
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.TableCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("TableCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <ParentRowOffsetInput
          value={parentRowOffset ?? ""}
          onChange={(event) => setParentRowOffset(parseInt(event.target.value))}
        />
        <ParentColumnOffsetInput
          value={parentColumnOffset ?? ""}
          onChange={(event) =>
            setParentColumnOffset(parseInt(event.target.value))
          }
        />
        <TableRowList rows={rows} setRows={setRows} />
        <TableColumnList columns={columns} setColumns={setColumns} />
        <AccessButton type="submit">
          {t("TableCreationTab.labels.add")}
        </AccessButton>
      </form>
    </NavbarTabContent>
  );
}
