import { mdiCheck, mdiClose } from "@mdi/js";
import { Icon } from "@mdi/react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { Button } from "@acdc2/ui/components/button";
import { Input } from "@acdc2/ui/components/input";
import { Label } from "@acdc2/ui/components/label";
import { Dispatch, SetStateAction, useId, useState } from "react";
import { useTranslation } from "react-i18next";
import TableDimensionInput from "./TableDimensionInput";
import { v4 as uuid } from "uuid";
import { UnsavedColumn } from "./types";

type Props = {
  columns: UnsavedColumn[];
  setColumns: Dispatch<SetStateAction<UnsavedColumn[]>>;
};

export default function TableColumnList({
  columns,
  setColumns,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const columnsListId = useId();

  const [inputVisibility, setInputVisibility] = useState<boolean>(false);
  const [newColumn, setNewColumn] = useState<UnsavedColumn | null>();

  const onSave = (index: number, newWidth: number) => {
    setColumns((oldColumns) => {
      const newColumns = [...oldColumns];
      newColumns[index].width = newWidth;
      return newColumns;
    });
  };

  const onDelete = (index: number) => {
    setColumns((oldColumns) => {
      const newColumns = [...oldColumns];
      newColumns.splice(index, 1);
      return newColumns;
    });
  };

  const onCancel = () => {
    setInputVisibility(false);
    setNewColumn(null);
  };

  const onAdd = () => {
    if (!newColumn) return;
    setColumns((oldColumns) => [...oldColumns, newColumn]);
    setInputVisibility(false);
    setNewColumn(null);
  };

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor={columnsListId}>{t("TableColumnList.title")}</Label>
      <div id={columnsListId} className="flex flex-col gap-2">
        {columns.map((column, index) => (
          <TableDimensionInput
            defaultValue={column.width}
            key={column.uuid}
            onDelete={() => onDelete(index)}
            onSave={(newWidth: number) => onSave(index, newWidth)}
          />
        ))}
      </div>
      {inputVisibility && (
        <div className="flex flex-row gap-2">
          <Input
            type="number"
            inputMode="numeric"
            min={0}
            max={1}
            step={0.01}
            placeholder={t("TableColumnList.labels.placeholder")}
            value={newColumn?.width ?? ""}
            onChange={(event) =>
              setNewColumn({
                uuid: uuid(),
                width: parseFloat(event.target.value),
              })
            }
            required
          />
          <Button
            type="button"
            size="icon"
            variant="outline"
            className="p-2"
            onClick={() => onAdd()}
          >
            <Icon path={mdiCheck} size={1} />
          </Button>
          <Button
            type="button"
            size="icon"
            variant="outline"
            className="p-2"
            onClick={() => onCancel()}
          >
            <Icon path={mdiClose} size={1} />
          </Button>
        </div>
      )}
      <AccessButton type="button" onClick={() => setInputVisibility(true)}>
        {t("TableColumnList.labels.add")}
      </AccessButton>
    </div>
  );
}
