import { Dispatch, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabIdentifier,
  TabPrefix,
} from "../../editor/reducers";
import {
  TableUpdateTabFragment,
  useUpdateTableAttributeMutation,
} from "../../client/generated";
import AttributeDeleteButton from "../AttributeDeleteButton";
import { useTranslation } from "react-i18next";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";
import { AccessButton } from "@acdc2/ui/components/access-button";
import ParentRowOffsetInput from "./ParentRowOffsetInput";
import ParentColumnOffsetInput from "./ParentColumnOffsetInput";
import TableRowList from "./TableRowList";
import TableColumnList from "./TableColumnList";
import { v4 as uuid } from "uuid";
import { UnsavedColumn, UnsavedRow } from "./types";

type Props = {
  fragment: TableUpdateTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function TableUpdateTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [parentRowOffset, setParentRowOffset] = useState(
    fragment.parentRowOffset,
  );
  const [parentColumnOffset, setParentColumnOffset] = useState(
    fragment.parentColumnOffset,
  );
  const [rows, setRows] = useState<UnsavedRow[]>(
    fragment.rows.map((height) => ({ uuid: uuid(), height })),
  );
  const [columns, setColumns] = useState<UnsavedColumn[]>(
    fragment.columns.map((width) => ({ uuid: uuid(), width })),
  );

  const [updateTableAttribute, { loading }] = useUpdateTableAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateTableAttribute({
      variables: {
        attributeId: fragment.id,
        parentRowOffset,
        parentColumnOffset,
        rows: rows.map((row) => row.height),
        columns: columns.map((column) => column.width),
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.TableUpdate, fragment.id)}
    >
      <NavbarTabHeader
        title={t("TableUpdateTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="flex flex-col p-4 gap-4" onSubmit={onSubmit}>
        <ParentRowOffsetInput
          value={parentRowOffset}
          onChange={(event) => setParentRowOffset(parseInt(event.target.value))}
        />
        <ParentColumnOffsetInput
          value={parentColumnOffset}
          onChange={(event) =>
            setParentColumnOffset(parseInt(event.target.value))
          }
        />
        <TableRowList rows={rows} setRows={setRows} />
        <TableColumnList columns={columns} setColumns={setColumns} />
        <AccessButton type="submit" loading={loading}>
          {t("TableUpdateTab.labels.confirm")}
        </AccessButton>
        <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
      </form>
    </NavbarTabContent>
  );
}
