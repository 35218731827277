
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Attribute": [
      "AlternativeTextAttribute",
      "CaptionAttribute",
      "ExcludeReadingOrderAttribute",
      "ExpressionAttribute",
      "FigureAttribute",
      "HeaderLevelAttribute",
      "ImageAttribute",
      "ListAttribute",
      "ListItemAttribute",
      "ListItemLabelAttribute",
      "PaginationArtifactAttribute",
      "TableAttribute",
      "TableCellAttribute",
      "TableHeaderCellAttribute",
      "TableOfContentsAttribute",
      "TableOfContentsHeadingAttribute",
      "TableOfContentsItemAttribute",
      "TableOfContentsPageNumberAttribute",
      "TextAttribute"
    ],
    "Connection": [
      "ArtifactConnection",
      "IssueConnection",
      "MembershipConnection",
      "PageConnection",
      "RegionConnection",
      "UserConnection"
    ],
    "Edge": [
      "ArtifactEdge",
      "IssueEdge",
      "MembershipEdge",
      "PageEdge",
      "RegionEdge"
    ],
    "Issue": [
      "AttributeConflictIssue"
    ],
    "Node": [
      "AlternativeTextAttribute",
      "Artifact",
      "AttributeConflictIssue",
      "CaptionAttribute",
      "Document",
      "ExcludeReadingOrderAttribute",
      "ExpressionAttribute",
      "FigureAttribute",
      "HeaderLevelAttribute",
      "ImageAttribute",
      "ListAttribute",
      "ListItemAttribute",
      "ListItemLabelAttribute",
      "MetadataAuthor",
      "MetadataSubject",
      "MetadataTitle",
      "Page",
      "PaginationArtifactAttribute",
      "Region",
      "TableAttribute",
      "TableCellAttribute",
      "TableHeaderCellAttribute",
      "TableOfContentsAttribute",
      "TableOfContentsHeadingAttribute",
      "TableOfContentsItemAttribute",
      "TableOfContentsPageNumberAttribute",
      "TextAttribute",
      "User"
    ],
    "Payload": [
      "AddAuthorPayload",
      "AddDocumentTagPayload",
      "AddSubjectPayload",
      "AddTitlePayload",
      "ChangeSubjectPayload",
      "ChangeTitleLanguagePayload",
      "CreateAlternativeTextAttributePayload",
      "CreateArtifactPayload",
      "CreateCaptionAttributePayload",
      "CreateExcludeReadingOrderAttributePayload",
      "CreateExpressionAttributePayload",
      "CreateFigureAttributePayload",
      "CreateHeaderLevelAttributePayload",
      "CreateImageAttributePayload",
      "CreateListAttributePayload",
      "CreateListItemAttributePayload",
      "CreateListItemLabelAttributePayload",
      "CreatePaginationArtifactAttributePayload",
      "CreateRegionPayload",
      "CreateTableAttributePayload",
      "CreateTableCellAttributePayload",
      "CreateTableHeaderCellAttributePayload",
      "CreateTableOfContentsAttributePayload",
      "CreateTableOfContentsHeadingAttributePayload",
      "CreateTableOfContentsItemAttributePayload",
      "CreateTableOfContentsPageNumberAttributePayload",
      "CreateTextAttributePayload",
      "DeleteArtifactPayload",
      "DeleteAttributePayload",
      "DeleteDocumentPayload",
      "DeleteRegionPayload",
      "RemoveAuthorPayload",
      "RemoveDocumentTagPayload",
      "RemoveSubjectPayload",
      "RemoveTitlePayload",
      "RenameAuthorPayload",
      "RenameDocumentPayload",
      "RenameTitlePayload",
      "SetDatePayload",
      "StartPageSegmentationPayload",
      "UpdateAlternativeTextAttributePayload",
      "UpdateCaptionAttributePayload",
      "UpdateDocumentPermissionPayload",
      "UpdateExpressionAttributePayload",
      "UpdateHeaderLevelAttributePayload",
      "UpdateListAttributePayload",
      "UpdateListItemAttributePayload",
      "UpdatePaginationArtifactAttributePayload",
      "UpdateRegionNextRegionPayload",
      "UpdateRegionReadingOrderIndexPayload",
      "UpdateRegionShapePayload",
      "UpdateTableAttributePayload",
      "UpdateTableCellAttributePayload",
      "UpdateTableHeaderCellAttributePayload",
      "UpdateTableOfContentsItemAttributePayload",
      "UpdateTextAttributePayload"
    ],
    "UserError": [
      "NoFixError"
    ]
  }
};
      export default result;
    