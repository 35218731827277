import { DocumentTagsSuggestionsFragment } from "../client/generated";
import { Badge } from "@acdc2/ui/components/badge";
import { Icon } from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import { useTranslation } from "react-i18next";

type Props = {
  fragment: DocumentTagsSuggestionsFragment;
  onTagAdd: (tag: string) => void;
};

export default function DocumentTagsSuggestions({
  fragment,
  onTagAdd,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="flex flex-wrap gap-x-1 gap-y-2">
      {fragment.possibleTags.map((tag) => (
        <Badge
          key={tag}
          variant="outline"
          className="gap-1 cursor-pointer"
          onClick={() => onTagAdd(tag)}
        >
          {tag}
          <Icon path={mdiPlus} size="1em" />
        </Badge>
      ))}
      {fragment.possibleTags.length === 0 && (
        <div className="text-sm text-muted-foreground">
          {t("DocumentTagsSuggestions.placeholder")}
        </div>
      )}
    </div>
  );
}
