import { useTranslation } from "react-i18next";
import { AccessTagInput } from "@acdc2/ui/components/access-tag-input";
import { DocumentTagsInputFragment } from "../client/generated";
import { ComponentProps } from "react";

type Props = Pick<
  ComponentProps<typeof AccessTagInput>,
  "onTagAdd" | "onTagRemove"
> & {
  fragment: DocumentTagsInputFragment;
};

export default function DocumentTagsInput({
  fragment,
  onTagAdd,
  onTagRemove,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AccessTagInput
      tags={fragment.tags}
      placeholder={t("DocumentTagsInput.placeholder")}
      onTagAdd={onTagAdd}
      onTagRemove={onTagRemove}
    />
  );
}
