import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { mdiTagMultiple } from "@mdi/js";
import { Trans, useTranslation } from "react-i18next";
import { AccessButton } from "@acdc2/ui/components/access-button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/dialog";
import {
  DocumentsScreenDocument,
  DocumentTagsDropdownMenuItemFragment,
  useTagDocumentMutation,
  useUntagDocumentMutation,
} from "../client/generated";
import DocumentTagsInput from "./DocumentTagsInput";
import DocumentTagsSuggestions from "./DocumentTagsSuggestions";

type Props = {
  fragment: DocumentTagsDropdownMenuItemFragment;
};

export default function DocumentTagsDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [tagDocument] = useTagDocumentMutation();
  const [untagDocument] = useUntagDocumentMutation();

  const onTagAdd = async (tag: string) => {
    try {
      await tagDocument({
        variables: {
          documentId: fragment.id,
          tag,
        },
        optimisticResponse: {
          addDocumentTag: {
            userErrors: [],
            document: {
              id: fragment.id,
              tags: [...fragment.tags, tag].sort(),
              possibleTags: fragment.possibleTags.filter((t) => t !== tag),
            },
          },
        },
        refetchQueries: [DocumentsScreenDocument],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onTagRemove = async (tag: string) => {
    try {
      await untagDocument({
        variables: {
          documentId: fragment.id,
          tag,
        },
        optimisticResponse: {
          removeDocumentTag: {
            userErrors: [],
            document: {
              id: fragment.id,
              tags: fragment.tags.filter((t) => t !== tag),
              possibleTags: [...fragment.possibleTags, tag].sort(),
            },
          },
        },
        refetchQueries: [DocumentsScreenDocument],
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiTagMultiple}
            description={t("DocumentTagsDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("DocumentTagsDropdownMenuItem.dialog.title")}
          </DialogTitle>
          <DialogDescription>
            <Trans
              i18nKey="DocumentTagsDropdownMenuItem.dialog.description"
              components={{ "word-break": <span className="break-all" /> }}
              values={{ name: fragment.name }}
            />
          </DialogDescription>
        </DialogHeader>

        <DocumentTagsInput
          fragment={fragment}
          onTagAdd={onTagAdd}
          onTagRemove={onTagRemove}
        />

        <div className="flex flex-col gap-3">
          <DialogTitle asChild>
            <h3>{t("DocumentTagsDropdownMenuItem.dialog.suggestions")}</h3>
          </DialogTitle>
          <DocumentTagsSuggestions fragment={fragment} onTagAdd={onTagAdd} />
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <AccessButton>
              {t("DocumentTagsDropdownMenuItem.dialog.done")}
            </AccessButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
