import { SortableRegionPreviewFragment } from "../client/generated";
import { Icon } from "@mdi/react";
import { mdiDrag } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { cn } from "@acdc2/ui/lib/utils";
import RegionThumbnail from "./RegionThumbnail";

type Props = {
  fragment: SortableRegionPreviewFragment;
};

export default function SortableRegionPreview({ fragment }: Props) {
  const { t } = useTranslation();

  return (
    <div className="border rounded overflow-hidden bg-gray-50 dark:bg-gray-900 cursor-grab">
      <div>
        <RegionThumbnail fragment={fragment} />
      </div>

      <div className="border-t flex p-2 justify-between items-center">
        <div
          className={cn("font-bold text-xs uppercase ", {
            "text-red-500": fragment.readingOrderIndex === 0,
            "text-brand": fragment.readingOrderIndex !== 0,
          })}
        >
          {fragment.readingOrderIndex === 0
            ? t("SortableRegionPreview.label.unordered")
            : t("SortableRegionPreview.label.ordered", {
                index: fragment.readingOrderIndex,
              })}
        </div>
        <div className="hover:bg-black hover:bg-opacity-5 p-1 rounded">
          <Icon path={mdiDrag} size="1rem" />
        </div>
      </div>
    </div>
  );
}
