import { useParams } from "react-router";
import { Fragment, useReducer } from "react";
import { usePageScreenSuspenseQuery } from "../client/generated";
import { editorReducer, TabIdentifier, RegionMode } from "../editor/reducers";
import Sidebar from "../shell/Sidebar";
import Scaffold from "../shell/Scaffold";
import EditorMetadataNavbarTabButton from "../editor/EditorMetadataNavbarTabButton";
import EditorMetadataNavbarTabContent from "../editor/EditorMetadataNavbarTabContent";
import EditorRegionsNavbarTabButton from "../editor/EditorRegionsNavbarTabButton";
import EditorRegionsNavbarTabContent from "../editor/EditorRegionsNavbarTabContent";
import EditorReadingOrderNavbarTabButton from "../editor/EditorReadingOrderNavbarTabButton";
import EditorReadingOrderNavbarTabContent from "../editor/EditorReadingOrderNavbarTabContent";
import EditorPagesNavbarTabButton from "../editor/EditorPagesNavbarTabButton";
import EditorPagesNavbarTabContent from "../editor/EditorPagesNavbarTabContent";
import OverviewNavbarButton from "../editor/OverviewNavbarButton";
import SidebarDivider from "../shell/SidebarDivider";
import PageEditor from "../editor/PageEditor";
import PreviousPageNavbarButton from "../editor/PreviousPageNavbarButton";
import NextPageNavbarButton from "../editor/NextPageNavbarButton";
import Navbar from "../shell/Navbar";
import AlternativeTextCreationTab from "../attributes/alternative-text/AlternativeTextCreationTab";
import CaptionCreationTab from "../attributes/caption/CaptionCreationTab";
import ExcludeReadingOrderCreationTab from "../attributes/exclude-reading-order/ExcludeReadingOrderCreationTab";
import ExpressionCreationTab from "../attributes/expression/ExpressionCreationTab";
import FigureCreationTab from "../attributes/figure/FigureCreationTab";
import HeadingLevelCreationTab from "../attributes/heading-level/HeadingLevelCreationTab";
import ImageCreationTab from "../attributes/image/ImageCreationTab";
import ListCreationTab from "../attributes/list/ListCreationTab";
import ListItemCreationTab from "../attributes/list-item/ListItemCreationTab";
import ListItemLabelCreationTab from "../attributes/list-item-label/ListItemLabelCreationTab";
import PaginationArtifactCreationTab from "../attributes/pagination-artifact/PaginationArtifactCreationTab";
import TableCreationTab from "../attributes/table/TableCreationTab";
import TableCellCreationTab from "../attributes/table-cell/TableCellCreationTab";
import TableHeaderCellCreationTab from "../attributes/table-header-cell/TableHeaderCellCreationTab";
import TableOfContentsCreationTab from "../attributes/table-of-contents/TableOfContentsCreationTab";
import TableOfContentsHeadingCreationTab from "../attributes/table-of-contents-heading/TableOfContentsHeadingCreationTab";
import TableOfContentsItemCreationTab from "../attributes/table-of-contents-item/TableOfContentsItemCreationTab";
import TableOfContentsPageNumberCreationTab from "../attributes/table-of-contents-page-number/TableOfContentsPageNumberCreationTab";
import TextCreationTab from "../attributes/text/TextCreationTab";
import AlternativeTextUpdateTab from "../attributes/alternative-text/AlternativeTextUpdateTab";
import CaptionUpdateTab from "../attributes/caption/CaptionUpdateTab";
import ExcludeReadingOrderUpdateTab from "../attributes/exclude-reading-order/ExcludeReadingOrderUpdateTab";
import ExpressionUpdateTab from "../attributes/expression/ExpressionUpdateTab";
import FigureUpdateTab from "../attributes/figure/FigureUpdateTab";
import HeadingLevelUpdateTab from "../attributes/heading-level/HeadingLevelUpdateTab";
import ImageUpdateTab from "../attributes/image/ImageUpdateTab";
import ListUpdateTab from "../attributes/list/ListUpdateTab";
import ListItemUpdateTab from "../attributes/list-item/ListItemUpdateTab";
import ListItemLabelUpdateTab from "../attributes/list-item-label/ListItemLabelUpdateTab";
import PaginationArtifactUpdateTab from "../attributes/pagination-artifact/PaginationArtifactUpdateTab";
import TableUpdateTab from "../attributes/table/TableUpdateTab";
import TableCellUpdateTab from "../attributes/table-cell/TableCellUpdateTab";
import TableHeaderCellUpdateTab from "../attributes/table-header-cell/TableHeaderCellUpdateTab";
import TableOfContentsUpdateTab from "../attributes/table-of-contents/TableOfContentsUpdateTab";
import TableOfContentsHeadingUpdateTab from "../attributes/table-of-contents-heading/TableOfContentsHeadingUpdateTab";
import TableOfContentsItemUpdateTab from "../attributes/table-of-contents-item/TableOfContentsItemUpdateTab";
import TableOfContentsPageNumberUpdateTab from "../attributes/table-of-contents-page-number/TableOfContentsPageNumberUpdateTab";
import TextUpdateTab from "../attributes/text/TextUpdateTab";

export default function PageScreen(): JSX.Element {
  const [state, dispatch] = useReducer(editorReducer, {
    mode: RegionMode.Rectangle,
    tabIdentifier: TabIdentifier.Metadata,
  });

  const { pageId } = useParams<{ pageId: string }>();

  const { data } = usePageScreenSuspenseQuery({
    variables: { pageId: pageId! },
  });

  if (data.page?.__typename !== "Page") {
    throw TypeError("Page not found");
  }

  return (
    <Scaffold
      tabsValue={state.tabIdentifier}
      onTabsValueChange={(tabIdentifier) => {
        dispatch({
          type: "switchTab",
          tabIdentifier,
        });
      }}
      navbar={
        <Navbar>
          <OverviewNavbarButton fragment={data.page.document} />
          <PreviousPageNavbarButton fragment={data.page} />
          <NextPageNavbarButton fragment={data.page} />
          <SidebarDivider />
          <EditorMetadataNavbarTabButton />
          <EditorPagesNavbarTabButton />
          <EditorRegionsNavbarTabButton />
          <EditorReadingOrderNavbarTabButton />
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <EditorMetadataNavbarTabContent fragment={data.page.document} />
          <EditorPagesNavbarTabContent fragment={data.page} />
          <EditorRegionsNavbarTabContent
            state={state}
            dispatch={dispatch}
            fragment={data.page}
          />
          <EditorReadingOrderNavbarTabContent
            key={data.page.id}
            fragment={data.page}
          />

          {data.page.regions.map((region) => (
            <Fragment key={region.id}>
              <AlternativeTextCreationTab
                dispatch={dispatch}
                fragment={region}
              />
              <CaptionCreationTab dispatch={dispatch} fragment={region} />
              <ExcludeReadingOrderCreationTab
                dispatch={dispatch}
                fragment={region}
              />
              <ExpressionCreationTab dispatch={dispatch} fragment={region} />
              <FigureCreationTab dispatch={dispatch} fragment={region} />
              <HeadingLevelCreationTab dispatch={dispatch} fragment={region} />
              <ImageCreationTab dispatch={dispatch} fragment={region} />
              <ListCreationTab dispatch={dispatch} fragment={region} />
              <ListItemCreationTab dispatch={dispatch} fragment={region} />
              <ListItemLabelCreationTab dispatch={dispatch} fragment={region} />
              <PaginationArtifactCreationTab
                dispatch={dispatch}
                fragment={region}
              />
              <TableCreationTab dispatch={dispatch} fragment={region} />
              <TableCellCreationTab dispatch={dispatch} fragment={region} />
              <TableHeaderCellCreationTab
                dispatch={dispatch}
                fragment={region}
              />
              <TableOfContentsCreationTab
                dispatch={dispatch}
                fragment={region}
              />
              <TableOfContentsHeadingCreationTab
                dispatch={dispatch}
                fragment={region}
              />
              <TableOfContentsItemCreationTab
                dispatch={dispatch}
                fragment={region}
              />
              <TableOfContentsPageNumberCreationTab
                dispatch={dispatch}
                fragment={region}
              />
              <TextCreationTab dispatch={dispatch} fragment={region} />

              <>
                {region.attributes.map((attribute) =>
                  attribute.__typename === "AlternativeTextAttribute" ? (
                    <AlternativeTextUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "CaptionAttribute" ? (
                    <CaptionUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename ===
                    "ExcludeReadingOrderAttribute" ? (
                    <ExcludeReadingOrderUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "ExpressionAttribute" ? (
                    <ExpressionUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "FigureAttribute" ? (
                    <FigureUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "HeaderLevelAttribute" ? (
                    <HeadingLevelUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "ImageAttribute" ? (
                    <ImageUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "ListAttribute" ? (
                    <ListUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "ListItemAttribute" ? (
                    <ListItemUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "ListItemLabelAttribute" ? (
                    <ListItemLabelUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "PaginationArtifactAttribute" ? (
                    <PaginationArtifactUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "TableAttribute" ? (
                    <TableUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "TableCellAttribute" ? (
                    <TableCellUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "TableHeaderCellAttribute" ? (
                    <TableHeaderCellUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "TableOfContentsAttribute" ? (
                    <TableOfContentsUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename ===
                    "TableOfContentsHeadingAttribute" ? (
                    <TableOfContentsHeadingUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename ===
                    "TableOfContentsItemAttribute" ? (
                    <TableOfContentsItemUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename ===
                    "TableOfContentsPageNumberAttribute" ? (
                    <TableOfContentsPageNumberUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : attribute.__typename === "TextAttribute" ? (
                    <TextUpdateTab
                      key={attribute.id}
                      fragment={attribute}
                      dispatch={dispatch}
                    />
                  ) : null,
                )}
              </>
            </Fragment>
          ))}
        </Sidebar>
      }
    >
      <PageEditor fragment={data.page} state={state} dispatch={dispatch} />
    </Scaffold>
  );
}
