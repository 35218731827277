import DocumentTableSearch from "./DocumentTableSearch";
import DocumentTableFilter from "./DocumentTableFilter";
import DocumentUploadButton from "./DocumentUploadButton";
import { useTranslation } from "react-i18next";
import { Table } from "@tanstack/react-table";
import {
  DocumentRowFragment,
  DocumentTableToolbarFragment,
} from "../client/generated";
import DocumentTableViewOptions from "./DocumentTableViewOptions";

type Props = {
  fragment: DocumentTableToolbarFragment;
  table: Table<DocumentRowFragment>;
};

export default function DocumentTableToolbar({
  fragment,
  table,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const uploaders = Array.from(
    new Set(
      fragment.memberships.map(
        (membership) => membership.document.uploadedBy.username,
      ),
    ),
  );

  const extensions = Array.from(
    new Set(
      fragment.memberships.map((membership) => membership.document.extension),
    ),
  );

  return (
    <div className="flex flex-col md:flex-row py-4 gap-2">
      <DocumentTableSearch
        column={table.getColumn("name")!}
        placeholder={t("DocumentTableToolbar.filters.title")}
      />
      <DocumentTableFilter
        column={table.getColumn("uploadedBy")!}
        title={t("DocumentTableToolbar.filters.uploader")}
        options={uploaders.map((uploader) => ({
          value: uploader,
          label: uploader,
        }))}
      />
      <DocumentTableFilter
        column={table.getColumn("extension")!}
        title={t("DocumentTableToolbar.filters.extension")}
        options={extensions.map((extension) => ({
          value: extension,
          label: extension.toUpperCase(),
        }))}
      />
      <div className="ml-auto flex flex-row gap-2">
        <DocumentTableViewOptions table={table} />
        <DocumentUploadButton />
      </div>
    </div>
  );
}
