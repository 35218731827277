import { useTranslation } from "react-i18next";
import AttributePreview from "../AttributePreview";
import { Dispatch } from "react";
import { EditorAction, makeIdentifier, TabPrefix } from "../../editor/reducers";
import { CaptionPreviewFragment } from "../../client/generated";

type Props = {
  fragment: CaptionPreviewFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function CaptionPreview({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <AttributePreview
      tabIdentifier={makeIdentifier(TabPrefix.CaptionUpdate, fragment.id)}
      label={t("CaptionPreview.label")}
      preview={t("CaptionPreview.preview", {
        page: fragment.region.page.index,
      })}
      dispatch={dispatch}
    />
  );
}
