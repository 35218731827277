import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@acdc2/ui/components/select";
import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { RegionSelectFragment } from "../client/generated";
import RegionThumbnail from "./RegionThumbnail";

type Props = ComponentProps<typeof Select> & {
  fragment: RegionSelectFragment;
  qualifyingAttributes: RegionSelectFragment["pages"]["pages"][number]["regions"][number]["attributes"][number]["__typename"][];
};

export default function RegionSelect({
  fragment,
  qualifyingAttributes,
  value,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation();

  const selectedRegion = fragment.regions.regions.find(
    (region) => region.id === value,
  );

  return (
    <Select value={value} {...props}>
      <SelectTrigger>
        <SelectValue placeholder={t("RegionSelect.placeholder")}>
          {t("RegionSelect.selection", { page: selectedRegion?.page.index })}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {fragment.pages.pages.map((page) => {
          const selectableRegions = page.regions.filter((region) =>
            region.attributes.some(
              (attr) =>
                attr.__typename &&
                qualifyingAttributes.includes(attr.__typename),
            ),
          );

          if (selectableRegions.length === 0) {
            return null;
          }

          return (
            <SelectGroup key={page.id}>
              <SelectLabel>
                {t("RegionSelect.groupLabel", { page: page.index })}
              </SelectLabel>
              {selectableRegions.map((region) => (
                <SelectItem
                  key={region.id}
                  value={region.id}
                  className="flex-col justify-center items-stretch"
                >
                  <RegionThumbnail fragment={region} />
                </SelectItem>
              ))}
            </SelectGroup>
          );
        })}
      </SelectContent>
    </Select>
  );
}
