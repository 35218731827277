import { TableOfContentsRegionSelectFragment } from "../../client/generated";
import { ComponentProps } from "react";
import RegionSelect from "../../regions/RegionSelect";

type Props = Omit<
  ComponentProps<typeof RegionSelect>,
  "qualifyingAttributes"
> & {
  fragment: TableOfContentsRegionSelectFragment;
};

export default function TableOfContentsRegionSelect({
  fragment,
  ...props
}: Props): JSX.Element {
  return (
    <RegionSelect
      {...props}
      fragment={fragment}
      qualifyingAttributes={["HeaderLevelAttribute", "CaptionAttribute"]}
    />
  );
}
