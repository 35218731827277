import { Button } from "@acdc2/ui/components/button";
import { Column } from "@tanstack/react-table";
import { Icon } from "@mdi/react";
import {
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiPlusCircleOutline,
} from "@mdi/js";
import { useTranslation } from "react-i18next";
import { DocumentRowFragment } from "../client/generated";
import { Badge } from "@acdc2/ui/components/badge";
import { Separator } from "@acdc2/ui/components/separator";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@acdc2/ui/components/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@acdc2/ui/components/command";

type Props = {
  column: Column<DocumentRowFragment>;
  title: string;
  options: { value: string; label: string }[];
};

export default function DocumentTableFilter({
  column,
  title,
  options,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const facets = column.getFacetedUniqueValues();
  const selectedValues = new Set(column.getFilterValue() as string[]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className="flex flex-row gap-4"
        >
          <div className="flex flex-row gap-1 items-center">
            <Icon path={mdiPlusCircleOutline} size="1rem" />
            {title}
          </div>
          {selectedValues.size > 0 && (
            <>
              <Separator orientation="vertical" />
              <div className="flex flex-row gap-1">
                {selectedValues.size > 2 ? (
                  <Badge variant="secondary">
                    {t("DocumentTableFilter.summary", {
                      size: selectedValues.size,
                    })}
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <Badge key={option.value} variant="secondary">
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>{t("DocumentTableFilter.empty")}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);

                return (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={() => {
                      if (isSelected) {
                        selectedValues.delete(option.value);
                      } else {
                        selectedValues.add(option.value);
                      }

                      const filterValues = Array.from(selectedValues);
                      column.setFilterValue(
                        filterValues.length > 0 ? filterValues : undefined,
                      );
                    }}
                  >
                    <Icon
                      path={
                        isSelected
                          ? mdiCheckboxMarkedOutline
                          : mdiCheckboxBlankOutline
                      }
                      size="1rem"
                      className="mr-2"
                    />
                    <span>{option.label}</span>
                    {facets.get(option.value) && (
                      <span className="ml-auto">
                        {facets.get(option.value)}
                      </span>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    className="justify-center"
                    onSelect={() => column.setFilterValue(undefined)}
                  >
                    {t("DocumentTableFilter.clear")}
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
