import { Column } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { DocumentRowFragment } from "../client/generated";
import DocumentTableHeader from "./DocumentTableHeader";

type Props = {
  column: Column<DocumentRowFragment, string>;
};

export default function DocumentUploadedAtTableHeader({
  column,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <DocumentTableHeader
      column={column}
      label={t("DocumentUploadedAtTableHeader.label")}
    />
  );
}
