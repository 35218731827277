import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@acdc2/ui/components/dropdown-menu";
import { Icon } from "@mdi/react";
import { mdiDotsVertical } from "@mdi/js";
import { DocumentTableActionsDropdownMenuFragment } from "../client/generated";
import DocumentRenameDropdownMenuItem from "./DocumentRenameDropdownMenuItem";
import DocumentExportDropdownMenuItem from "./DocumentExportDropdownMenuItem";
import DocumentDeleteDropdownMenuItem from "./DocumentDeleteDropdownMenuItem";
import DocumentShareDropdownMenuItem from "./DocumentShareDropdownMenuItem";
import DocumentTagsDropdownMenuItem from "./DocumentTagsDropdownMenuItem";

type Props = {
  fragment: DocumentTableActionsDropdownMenuFragment;
};

export default function DocumentTableActionsDropdownMenu({
  fragment,
}: Props): JSX.Element {
  return (
    <div className="flex justify-end">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Icon
            path={mdiDotsVertical}
            size={1}
            className="flex flex-center items-center"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DocumentTagsDropdownMenuItem fragment={fragment} />
          <DocumentShareDropdownMenuItem fragment={fragment} />
          <DocumentRenameDropdownMenuItem fragment={fragment} />
          <DocumentExportDropdownMenuItem fragment={fragment} />
          <DocumentDeleteDropdownMenuItem fragment={fragment} />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
