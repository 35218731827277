import { Dispatch, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  CaptionCreationTabFragment,
  useCreateCaptionAttributeMutation,
} from "../../client/generated";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";
import CaptionRegionSelect from "./CaptionRegionSelect";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: CaptionCreationTabFragment;
};

export default function CaptionCreationTab({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [linkedRegion, setLinkedRegion] = useState<string>();

  const [createCaptionAttribute] = useCreateCaptionAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await createCaptionAttribute({
      variables: {
        regionId: fragment.id,
        linkedRegionId: linkedRegion,
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.CaptionCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("CaptionCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <CaptionRegionSelect
          fragment={fragment.page.document}
          value={linkedRegion}
          onValueChange={setLinkedRegion}
        />
        <AccessButton type="submit">
          {t("CaptionCreationTab.labels.add")}
        </AccessButton>
      </form>
    </NavbarTabContent>
  );
}
