import { cn } from "@acdc2/ui/lib/utils";

type AccessContextMenuContentProps = {
  className?: string;
  children?: React.ReactNode;
};

type AccessContextMenuItemProps = {
  className?: string;
  children?: React.ReactNode;
  inset?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};

type AccessContextMenuSeparatorProps = {
  className?: string;
};

type AccessContextMenuShortcutProps = {
  className?: string;
  children?: React.ReactNode;
};

export function AccessContextMenuContent({
  className,
  children,
}: AccessContextMenuContentProps): JSX.Element {
  return (
    <div
      className={cn(
        "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md animate-in fade-in-80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        className,
      )}
    >
      {children}
    </div>
  );
}

export function AccessContextMenuItem({
  className,
  children,
  inset,
  onClick,
  disabled,
}: AccessContextMenuItemProps): JSX.Element {
  return (
    <div
      onClick={onClick}
      className={cn(
        "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent focus:text-accent-foreground hover:bg-accent",
        inset && "pl-8",
        disabled && "opacity-50 pointer-events-none",
        className,
      )}
    >
      {children}
    </div>
  );
}

export function AccessContextMenuSeparator({
  className,
}: AccessContextMenuSeparatorProps): JSX.Element {
  return <div className={cn("-mx-1 my-1 h-px bg-border", className)} />;
}

export function AccessContextMenuShortcut({
  className,
  children,
}: AccessContextMenuShortcutProps): JSX.Element {
  return (
    <span
      className={cn(
        "ml-auto text-xs tracking-widest text-muted-foreground",
        className,
      )}
    >
      {children}
    </span>
  );
}
